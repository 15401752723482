import React from "react";
import styled from "styled-components";
import "react-image-gallery/styles/css/image-gallery.css";

import ImageGallery from "react-image-gallery";

import Fade from "react-reveal/Fade";

import ImageO1 from "../images/ursus/01_600.jpg";
import ImageO2 from "../images/ursus/02_600.jpg";
import ImageO3 from "../images/ursus/03_600.jpg";
import ImageO4 from "../images/ursus/04_600.png";
import ImageO5 from "../images/ursus/05_600.png";
import ImageO6 from "../images/ursus/06_600.png";
import ImageO7 from "../images/ursus/07_600.png";
import ImageO8 from "../images/ursus/08_600.png";
import ImageO9 from "../images/ursus/09_600.png";
import ImageO10 from "../images/ursus/10_600.png";
import ImageO11 from "../images/ursus/11_600.jpg";
import ImageO12 from "../images/ursus/12_600.jpg";
import ImageO13 from "../images/ursus/13_600.jpg";
import ImageO14 from "../images/ursus/14_600.jpg";
import ImageO15 from "../images/ursus/15_600.jpg";
import ImageO16 from "../images/ursus/16_600.jpg";
import ImageO17 from "../images/ursus/17_600.jpg";
import ImageO18 from "../images/ursus/18_600.jpg";
import ImageO19 from "../images/ursus/19_600.jpg";

import ImageT1 from "../images/ursus/01_150.jpg";
import ImageT2 from "../images/ursus/02_150.jpg";
import ImageT3 from "../images/ursus/03_150.jpg";
import ImageT4 from "../images/ursus/04_150.png";
import ImageT5 from "../images/ursus/05_150.png";
import ImageT6 from "../images/ursus/06_150.png";
import ImageT7 from "../images/ursus/07_150.png";
import ImageT8 from "../images/ursus/08_150.png";
import ImageT9 from "../images/ursus/09_150.png";
import ImageT10 from "../images/ursus/10_150.png";
import ImageT11 from "../images/ursus/11_150.jpg";
import ImageT12 from "../images/ursus/12_150.jpg";
import ImageT13 from "../images/ursus/13_150.jpg";
import ImageT14 from "../images/ursus/14_150.jpg";
import ImageT15 from "../images/ursus/15_150.jpg";
import ImageT16 from "../images/ursus/16_150.jpg";
import ImageT17 from "../images/ursus/17_150.jpg";
import ImageT18 from "../images/ursus/18_150.jpg";
import ImageT19 from "../images/ursus/19_150.jpg";

const GalleryContainer = styled.div`
  width: 55%;
  height: 55%;
  margin: auto;
  margin-top: -10rem;
  margin-bottom: 3rem;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

const images = [
  {
    original: ImageO1,
    thumbnail: ImageT1,
  },
  {
    original: ImageO2,
    thumbnail: ImageT2,
  },
  {
    original: ImageO3,
    thumbnail: ImageT3,
  },
  {
    original: ImageO4,
    thumbnail: ImageT4,
  },
  {
    original: ImageO5,
    thumbnail: ImageT5,
  },
  {
    original: ImageO6,
    thumbnail: ImageT6,
  },
  {
    original: ImageO7,
    thumbnail: ImageT7,
  },
  {
    original: ImageO8,
    thumbnail: ImageT8,
  },
  {
    original: ImageO9,
    thumbnail: ImageT9,
  },
  {
    original: ImageO10,
    thumbnail: ImageT10,
  },
  {
    original: ImageO11,
    thumbnail: ImageT11,
  },
  {
    original: ImageO12,
    thumbnail: ImageT12,
  },
  {
    original: ImageO13,
    thumbnail: ImageT13,
  },
  {
    original: ImageO14,
    thumbnail: ImageT14,
  },
  {
    original: ImageO15,
    thumbnail: ImageT15,
  },
  {
    original: ImageO16,
    thumbnail: ImageT16,
  },
  {
    original: ImageO17,
    thumbnail: ImageT17,
  },
  {
    original: ImageO18,
    thumbnail: ImageT18,
  },
  {
    original: ImageO19,
    thumbnail: ImageT19,
  },
];

export const MyGallery1 = () => {
  return (
    <GalleryContainer>
      <Fade bottom>
        <ImageGallery
          items={images}
          showPlayButton={true}
          slideInterval={5000}
          slideOnThumbnailOver={true}
          slideDuration={450}
          autoPlay={true}
        />
        <br />
      </Fade>
    </GalleryContainer>
  );
};

export default MyGallery1;
