import Image1 from "../images/church-5541729_1280.jpg";
import Image2 from "../images/Warsaw-center-free-license-CC0.jpg";
import Image3 from "../images/city-6529111_1280.jpg";
import Image4 from "../images/warsaw-6746525_1280.jpg";

export const SliderData = [
  {
    title: "NOWA INWESTYCJA",
    paragraph: "LOKALIZACJA: KRAKÓW, PODGÓRZE",
    image: Image1,
  },
  {
    title: "LOKALE NA SPRZEDAŻ",
    paragraph: "LOKALIZACJA: WARSZAWA, PRAGA-PÓŁNOC",
    image: Image2,
  },
  {
    title: "LOKALE NA SPRZEDAŻ",
    paragraph: "LOKALIZACJA: WARSZAWA, URSUS",
    image: Image3,
  },
  {
    title: "LOKALE NA SPRZEDAŻ",
    paragraph: "LOKALIZACJA: WARSZAWA, WŁOCHY",
    image: Image4,
  },
];
