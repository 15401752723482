import React from "react";
import styled from "styled-components";
import { Fade } from "react-reveal";

const Heading = styled.div`
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding: 2rem;

  h1 {
    margin-bottom: 1rem;
    font-size: clamp(1.5rem, 6vw, 3rem);
    text-align: center;
  }

  p {
    font-size: clamp(1.5rem, 6vw, 1rem);
    display: flex;
    justify-content: center;
    text-align: justify;
    padding: 3rem calc((100vw - 1600px) / 2);
    white-space: pre-line;
  }

  @media screen and (max-width: 768px) {
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding: 2rem 3rem;
  }
`;

const HeadingSection = ({ headings, paragraphs }) => {
  return (
    <Heading>
      <Fade top>
        <h1>{headings}</h1>
      </Fade>
      <Fade bottom>
        <p>{paragraphs}</p>
      </Fade>
    </Heading>
  );
};

export default HeadingSection;
