import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";

const StyledContactForm = styled.div`
  width: 100%;

  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

    @media screen and (max-width: 768px) {
      width: 350px;
      margin-bottom: 2rem;
    }
  }

  input,
  textarea {
    margin-right: 12.5rem;
    width: 100%;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);

    &:focus {
      border: 1px solid rgba(0, 206, 158, 1);
    }
  }

  input {
    height: 35px;
  }

  textarea {
    height: 300px;
  }

  label {
    margin-top: 1rem;
  }

  .checkbox-label {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  .checkbox-input {
    margin-right: 0.5rem;
    margin-top: 1rem;
    width: 15px;
    height: 15px;
  }

  input[type="submit"] {
    min-width: 100px;
    max-width: 150px;
    margin: 2rem auto;

    cursor: pointer;
    background: rgb(249, 105, 14);
    color: white;
    border: none;

    &:hover {
      background: red;
    }
  }

  h1 {
    width: 100%;
    text-align: center;
  }

  p {
    width: 100%;
    text-align: center;
  }
`;

export const Contact = () => {
  const form = useRef();

  const [messageSent, setMessageSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_538755e",
        "template_6n92p09",
        form.current,
        "Xi1fFZZItXwl6Zo56"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("Wiadomość dostarczona");
          setMessageSent(true);
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    let timer;
    if (messageSent) {
      timer = setTimeout(() => {
        setMessageSent(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [messageSent]);

  return (
    <StyledContactForm>
      <form ref={form} onSubmit={sendEmail}>
        <h1>FORMULARZ</h1>
        <label>Imię</label>
        <input type="text" name="user_name" />
        <label>Email</label>
        <input type="email" name="user_email" />
        <label>Telefon</label>
        <input type="phone" name="user_phone" />
        <label>Wiadomość</label>
        <textarea name="message" />
        <div className="checkbox-label">
          <input className="checkbox-input" type="checkbox" required />
          <label>Akceptuję politykę prywatności.</label>
        </div>
        <input type="submit" value="Wyślij" />
        {messageSent && <p>Wiadomość dostarczona</p>}
      </form>
    </StyledContactForm>
  );
};

export default Contact;
