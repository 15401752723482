import React from "react";
import styled from "styled-components";
import { Button } from "./Button";

import Fade from "react-reveal/Fade";

const Section = styled.section`
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;

  @media screen and (max-width: 768px) {
    padding: 2rem 0;
  }
`;

const Container = styled.div`
  padding: 3rem calc((100vw - 1600px) / 2);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 2rem 1rem;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  align-items: flex-start;
  line-height: 1.4;
  padding: 1rem 2rem;
  transform: scale(0.9);
  order: ${({ reverse }) => (reverse ? "2" : "1")};

  h1 {
    margin-bottom: 2rem;
    font-size: clamp(1.5rem, 6vw, 2rem);
  }
  p {
    margin-bottom: 2rem;
    white-space: pre-line;
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }
`;

const ColumnRight = styled.div`
  padding: 1rem 2rem;
  flex-direction: ${({ imageLocation }) =>
    imageLocation === "left" ? "row" : "column"};
  order: ${({ reverse }) => (reverse ? "1" : "2")};
  display: flex;
  justify-content: center;
  text-align: justify;
  transform: scale(0.9);
  align-items: ${({ imageLocation }) =>
    imageLocation === "left" ? "center" : "flex-start"};

  @media screen and (max-width: 768px) {
    order: ${({ reverse }) => (reverse ? "2" : "1")};
  }

  > h1 {
    margin-bottom: 2rem;
    font-size: clamp(1.5rem, 6vw, 2rem);
  }

  > p {
    margin-bottom: 2rem;
    font-size: clamp(1.5rem, 6vw, 1rem);
    white-space: pre-line;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }
`;

const InfoSection = ({
  heading,
  paragraph,
  price,
  buttonLabel,
  buttonLink,
  reverse,
  image,
  imageLocation,
}) => {
  return (
    <Section>
      <Fade left>
        <Container>
          <ColumnLeft>
            {imageLocation === "left" ? (
              <img src={image} alt="home" />
            ) : (
              <>
                <h1>{heading}</h1>
                <p>{paragraph}</p>
                <h1>{price}</h1>
                <Button
                  to={buttonLink}
                  primary="true"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(buttonLink, "_blank");
                  }}
                >
                  {buttonLabel}
                </Button>
              </>
            )}
          </ColumnLeft>
          <ColumnRight reverse={reverse}>
            {imageLocation === "left" ? (
              <>
                <h1>{heading}</h1>
                <p>{paragraph}</p>
                <h1>{price}</h1>
                <Button
                  to={buttonLink}
                  primary="true"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(buttonLink, "_blank");
                  }}
                >
                  {buttonLabel}
                </Button>
              </>
            ) : (
              <img src={image} alt="home" />
            )}
          </ColumnRight>
        </Container>
      </Fade>
    </Section>
  );
};

export default InfoSection;
