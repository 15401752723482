import React from "react";
import styled from "styled-components";
import "react-image-gallery/styles/css/image-gallery.css";

import ImageGallery from "react-image-gallery";

import Fade from "react-reveal/Fade";

import ImageO1 from "../images/k1_600.png";
import ImageO2 from "../images/k2_600.png";
import ImageO3 from "../images/przyziemie_600.png";
import ImageO4 from "../images/parter_600.png";
import ImageO5 from "../images/poddasze_600.png";

import ImageT1 from "../images/k1_150.png";
import ImageT2 from "../images/k2_150.png";
import ImageT3 from "../images/przyziemie_150.png";
import ImageT4 from "../images/parter_150.png";
import ImageT5 from "../images/poddasze_150.png";

const GalleryContainer = styled.div`
  width: 55%;
  height: 55%;
  margin: auto;
  margin-top: -10rem;
  margin-bottom: 3rem;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

const images = [
  {
    original: ImageO1,
    thumbnail: ImageT1,
  },
  {
    original: ImageO2,
    thumbnail: ImageT2,
  },
  {
    original: ImageO3,
    thumbnail: ImageT3,
  },
  {
    original: ImageO4,
    thumbnail: ImageT4,
  },
  {
    original: ImageO5,
    thumbnail: ImageT5,
  },
];

export const MyGallery = () => {
  return (
    <GalleryContainer>
      <Fade bottom>
        <ImageGallery
          items={images}
          showPlayButton={true}
          slideInterval={5000}
          slideOnThumbnailOver={true}
          slideDuration={450}
          autoPlay={true}
        />
        <br />
      </Fade>
    </GalleryContainer>
  );
};

export default MyGallery;
