export const HeadingData1 = [
  {
    headings: "O FIRMIE",
    paragraphs:
      "Jesteśmy jedną z wiodących firm działających na rynku nieruchomości od 1989 roku. Nasze długoletnie doświadczenie i zaangażowanie w branży sprawiają, że jesteśmy niezawodnym partnerem dla klientów poszukujących nieruchomości.  \n\nNasza główna działalność koncentruje się na sprzedaży własnych nieruchomości, które są dostępne zarówno dla klientów indywidualnych, jak i inwestorów. Mamy szeroki wybór nieruchomości, które spełnią Twoje oczekiwania, niezależnie od tego, czy szukasz mieszkania dla siebie i swojej rodziny, czy chcesz zainwestować w nieruchomość generującą dochód. \n\nOprócz sprzedaży, prowadzimy również skup nieruchomości. Jeśli posiadają Państwo nieruchomość na terenie Polski lub za granicą, którą rozważacie jej sprzedaż, możemy być zainteresowani jej zakupem. Oferujemy uczciwą wycenę oraz elastyczne warunki, aby zapewnić klientom szybką i sprawiedliwą transakcję. \n\nNie pobieramy prowizji, pokrywamy koszty i przygotujemy obiekt do sprzedaży. Jeśli w ciągu roku nie znajdziemy klienta, po tym czasie gwarantujemy odkupienie nieruchomości.\n\nOferujemy szereg usług dodatkowych, aby zapewnić naszym klientom pełne wsparcie na każdym etapie transakcji.",
  },
];

export const HeadingData2 = [
  {
    headings: "OFERTA",
    paragraphs:
      "Inwestycja mieszkalna przy ulicy Borsuczej w Krakowie to nowoczesne i prestiżowe osiedle, idealne dla osób poszukujących komfortowego miejsca do zamieszkania w dynamicznym mieście. Zlokalizowana w południowej części miasta, ulica Borsucza oferuje dogodny dostęp do licznych atrakcji i udogodnień, takich jak restauracje, sklepy, parki i centra handlowe.\n\nObiekt został starannie zaprojektowany z myślą o komforcie i wygodzie mieszkańców. Inwestycja składa się z budynku mieszkalnego o wysokim standardzie wykończenia i estetycznym designie. Dostępne są różne metraże i układy mieszkań, aby zaspokoić potrzeby i preferencje klientów.\n\nBudynek posiada charakter zabudowy willowej, który na obecną chwilę jest w trakcie adaptacji, aby spełnić odpowiednie wysokie wymogi kupujących. Dodatkowo, osiedle oferuje liczne udogodnienia dla mieszkańców, takie jak wspólny teren rekreacyjny 'Park rzeczny Wilga', place zabaw dla dzieci oraz parking. W pobliżu znajdują się również przystanki komunikacji miejskiej, co ułatwia podróżowanie i dostęp do innych części miasta.\n\nInwestycja mieszkalna przy ulicy Borsuczej w Krakowie to idealne rozwiązanie dla osób, które cenią sobie wygodne i centralnie położone miejsce zamieszkania. Zapewnia ona nie tylko na odpowiednim poziomie standard życia, ale także bliskość do ważnych punktów i atrakcji w mieście.",
  },
];

export const HeadingData3 = [
  {
    headings: "GALERIA",
  },
];

export const HeadingData4 = [
  {
    headings: "SPRZEDAŻ",
    paragraphs:
      "Na sprzedaż mamy szeroki wybór nieruchomości i działek o atrakcyjnych lokalizacjach, z których wiele znajduje się w prestiżowych miastach, takich jak Kraków i Warszawa. \n\nNasza oferta obejmuje komfortowe mieszkania w centrum miast, a także atrakcyjne lokale użytkowe w renomowanych dzielnicach biznesowych. \n\nDysponujemy dogłębną znajomością lokalnych rynków nieruchomości, co umożliwia nam dostarczenie rzetelnych informacji i doradztwa w procesie wyboru. Pracujemy z wiarygodnymi deweloperami i właścicielami nieruchomości, aby zapewnić naszym klientom pewność co do stanu technicznego, wykończenia i dokumentacji. \n\nZapraszamy do zapoznania się z naszą aktualną ofertą nieruchomości. Nasz zespół jest gotów udzielić odpowiedzi na wszelkie pytania otrzymane od Państwa. Zorganizujemy prezentację nieruchomości, pomożemy w procesie zakupu oraz przygotujemy szczegółowe informacje i niezbędne dokumenty związane z sformalizowaniem transakcji. Czekamy na możliwość spełnienia Twoich potrzeb i zapewnienia Państwu udanego inwestycyjnego doświadczenia.",
  },
];

export const HeadingData5 = [
  {
    headings: "ZDJĘCIA MIESZKANIA",
  },
];