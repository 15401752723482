import React, { useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import styled from "styled-components";

const Button = styled.button`
  position: fixed;
  bottom: 30px;
  right: 30px;
  border: none;
  font-size: 20px;
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  z-index: 999;
  width: 50px;
  height: 50px;
  color: #fff;
  cursor: ${(props) => (props.isVisible ? "pointer" : "auto")};
  background: #000d1a;
  border-radius: 50px;
  padding: 10px;
  margin-right: 1rem;
  user-select: none;
  transition: 0.3s;

  &:hover {
    background: #1974d2;
    transform: scale(1.05);
  }
`;

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", handleScroll);

  return (
    <Button isVisible={isVisible ? "visible" : ""} onClick={scrollToTop}>
      <FaArrowUp />
    </Button>
  );
};

export default ScrollToTopButton;
