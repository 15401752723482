import React from "react";
import styled from "styled-components";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { ImLocation } from "react-icons/im";
import ContactForm from "./ContactForm";

const FooterContainer = styled.footer`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: auto;
  height: auto;

  background: #000d1a;
  color: #fff;

  padding: 3rem calc((100vw - 1600px) / 2);

  p {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 1rem 4.5rem;
    justify-content: center;
    gap: 1rem;

    width: auto;
    height: auto;
    padding-top: 5rem;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1rem 2rem;

  > h1 {
    font-size: 3.5rem;
  }
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  padding: 1rem 4rem;

  align-items: center;
`;

const ContainerItems = styled.div`
  display: flex;
  gap: 2.5rem;

  > ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  > ul li {
    list-style: none;

    transition: 0.3s;

    &:hover {
      text-decoration: none;
    }
  }
`;

const EmailLink = styled.a`
  color: white;
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    color: red;
    text-decoration: none;
  }
`;

export const Footer = () => {
  return (
    <FooterContainer>
      <LeftColumn>
        <ContainerItems>
          <ul>
            <li>
              <h1>KONTAKT</h1>
            </li>
            <li>
              <h3>
                GRUDNIK FINANSE SP Z O. O. /<br /> NIERUCHOMOŚCI
              </h3>
            </li>

            <li>
              <BsTelephone /> 600 999 649
            </li>
            <li>
              <BsTelephone /> 501 427 466
            </li>
            <li>
              <BsTelephone /> 794 546 646
            </li>
            <li>
              <AiOutlineMail />{" "}
              <EmailLink href="mailto:mieszkania@grudnik.pl">
                mieszkania@grudnik.pl
              </EmailLink>
            </li>
            <li>
              <ImLocation color="#ffffff" /> Al. Jerozolimskie 11/19, lok. 33,{" "}
              <br />
              00-508 Warszawa
            </li>

            <li>NIP: 9451956142</li>
            <li>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.6949042601104!2d21.017385899999997!3d52.230760599999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471eccf154ef74a1%3A0x58b2e3f1e1589e68!2sal.%20Jerozolimskie%2011%2F19%2C%2000-508%20Warszawa!5e0!3m2!1spl!2spl!4v1684876096895!5m2!1spl!2spl"
                width="400"
                height="300"
                styles="border:0;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </li>
          </ul>
        </ContainerItems>
      </LeftColumn>

      <RightColumn>
        <ContactForm />
      </RightColumn>
      <p>&copy; {new Date().getFullYear()} Grudnik Finanse sp. z o. o.</p>
    </FooterContainer>
  );
};

export default Footer;
