import Image1 from "../images/pexels-photo-6077797.webp";
import Image2 from "../images/pexels-photo-3760067.webp";
import Image3 from "../images/pexels-photo-834892.jpeg";

export const CompanyData1 = {
  heading: "Obsługa prawna",
  paragraph:
    "Nasz zespół doświadczonych prawników zapewni kompleksową obsługę prawną, aby mogli Państwo mieć pewność, że proces transakcji jest zgodny z obowiązującymi przepisami prawnymi.",
  image: Image1,
  reverse: false,
  delay: 100,
};

export const CompanyData2 = {
  heading: "Pomoc w pozyskaniu dofinansowania",
  paragraph:
    "Współpracujemy z renomowanymi instytucjami finansowymi, które oferują konkurencyjne warunki kredytowe. Nasz zespół doradców finansowych pomoże znaleźć najkorzystniejsze rozwiązanie, które dostosowane jest do Państwa indywidualnych potrzeb i możliwości.",
  image: Image2,
  reverse: true,
  delay: 100,
  imageLocation: "left",
};

export const CompanyData3 = {
  heading: "Wykonanie remontów",
  paragraph:
    "Dodatkowo, jeśli nieruchomość wymaga remontu lub modernizacji, możemy zająć się tym za Was. Nasza ekipa doświadczonych fachowców przeprowadzi remont zgodnie z Państwa oczekiwaniami i terminem, zapewniając wysoką jakość wykonania prac.",
  image: Image3,
  reverse: false,
  delay: 100,
};
