import React, { useState } from "react";

import { Navbar } from "./components/Navbar";
import { GlobalStyle } from "./globalStyles";
import Hero from "./components/Hero";
import { SliderData } from "./data/SliderData";
import Dropdown from "./components/Dropdown";
import InfoSection from "./components/InfoSection";
import {
  Dzialka1,
  Dzialka2,
  Dzialka3,
  Dzialka4,
  Lokal1,
  Lokal2,
  Lokal3,
  Lokal4,
  Lokal5,
  Lokal6,
  Lokal7,
  Lokal8,
  Lokal9,
  Lokal10,
  Lokal11,
} from "./data/InfoData";

import CompanySection from "./components/Company";
import { CompanyData1, CompanyData2, CompanyData3 } from "./data/CompanyData";

import { Footer } from "./components/Footer";

import ScrollToTopButton from "./components/ScrollToTopButton";

import HeadingSection from "./components/Headings";
import {
  HeadingData1,
  HeadingData2,
  HeadingData3,
  HeadingData4,
  HeadingData5,
} from "./data/HeadingData";

import { MyGallery } from "./components/Gallery";
import { MyGallery1 } from "./components/GalleryUrsus";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <GlobalStyle />
      <Navbar toggle={toggle} />
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <div id="/" />
      <Hero slides={SliderData} />

      <div id="o-firmie" />
      <HeadingSection
        headings={HeadingData1[0].headings}
        paragraphs={HeadingData1[0].paragraphs}
      />

      <CompanySection {...CompanyData1} />
      <CompanySection {...CompanyData2} />
      <CompanySection {...CompanyData3} />

      <div id="oferta" />
      <HeadingSection
        headings={HeadingData2[0].headings}
        paragraphs={HeadingData2[0].paragraphs}
      />

      

      <InfoSection {...Dzialka1} />
      <InfoSection {...Dzialka2} />
      

      <div id="galeria" />
      <HeadingSection headings={HeadingData3[0].headings} />
      <MyGallery />
      <br />

      <div id="sprzedaz" />
      <HeadingSection
        headings={HeadingData4[0].headings}
        paragraphs={HeadingData4[0].paragraphs}
      />

      <InfoSection {...Dzialka4} />

      <InfoSection {...Lokal2} />
      <InfoSection {...Lokal3} />
      <InfoSection {...Lokal4} />
      <InfoSection {...Lokal5} />
      
      
      <InfoSection {...Lokal8} />
      <InfoSection {...Lokal9} />
      
      <InfoSection {...Lokal11} />

      <HeadingSection headings={HeadingData5[0].headings} />
      <MyGallery1 />

      <div id="kontakt" />
      <Footer />
      <ScrollToTopButton />
    </>
  );
}

export default App;
