import React from "react";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";

const DropdownContainer = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #1974d2;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;
const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;
const CloseIcon = styled(FaTimes)`
  color: #fff;
`;
const DropdownWrapper = styled.div``;

const DropdownMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, 80px);
  text-align: center;
  margin-bottom: 4rem;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(5, 60px);
  }
`;

const DropdownLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    color: #000d1a;
  }
`;

const Dropdown = ({ isOpen, toggle }) => {
  return (
    <DropdownContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <DropdownWrapper>
        <DropdownMenu>
          <DropdownLink
            to="o-firmie"
            smooth={true}
            duration={500}
            isOpen={!isOpen}
            onClick={toggle}
          >
            O firmie
          </DropdownLink>
          <DropdownLink
            to="oferta"
            smooth={true}
            duration={500}
            isOpen={!isOpen}
            onClick={toggle}
          >
            Oferta
          </DropdownLink>
          <DropdownLink
            to="galeria"
            smooth={true}
            duration={500}
            isOpen={!isOpen}
            onClick={toggle}
          >
            Galeria
          </DropdownLink>
          <DropdownLink
            to="sprzedaz"
            smooth={true}
            duration={500}
            isOpen={!isOpen}
            onClick={toggle}
          >
            Sprzedaż
          </DropdownLink>
          <DropdownLink
            to="kontakt"
            smooth={true}
            duration={500}
            isOpen={!isOpen}
            onClick={toggle}
          >
            Kontakt
          </DropdownLink>
        </DropdownMenu>
      </DropdownWrapper>
    </DropdownContainer>
  );
};

export default Dropdown;
