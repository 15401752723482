import Plot1 from "../images/dzialka-inna-krakow-podgorze.webp";
import Plot2 from "../images/dzialka-na-sprzedaz-krakow-bronowice-250-m.webp";
import Plot3 from "../images/dzialka-na-sprzedaz-krakow-500-m.webp";
import Plot4 from "../images/dzialka-na-sprzedaz-warszawa-wlochy-526-m.webp";

import Venue1 from "../images/mieszkanie-na-sprzedaz-krakow-podgorze-42-m.webp";
import Venue2 from "../images/mieszkanie-na-sprzedaz-warszawa-wlochy-30-m.webp";
import Venue3 from "../images/lokal-komercyjny-na-sprzedaz-warszawa-praga-polnoc-78-m.webp";
import Venue4 from "../images/nieruchomosc-komercyjna-na-sprzedaz-warszawa-praga-polnoc-15-m.webp";
import Venue5 from "../images/biuro-na-sprzedaz-warszawa-praga-polnoc-18-m.webp";
import Venue6 from "../images/lokal-komercyjny-na-sprzedaz-warszawa-praga-polnoc-8-m.webp";
import Venue7 from "../images/lokal-komercyjny-na-sprzedaz-warszawa-praga-polnoc-430-m.webp";
import Venue8 from "../images/lokal-komercyjny-na-sprzedaz-warszawa-praga-polnoc-14-m.webp";
import Venue9 from "../images/mieszkanie-na-sprzedaz-warszawa-praga-polnoc-46-m.webp";
import Venue10 from "../images/kawalerka-na-sprzedaz-warszawa-ursus-24-m.webp";
import Venue11 from "../images/mieszkanie-na-sprzedaz-warszawa-ursus-60-m.webp";

export const Dzialka1 = {
  heading: "Działka 350 m2 - Kraków, Podgórze",
  paragraph:
  "- działka budowlana w kształcie litery L\n\n- częściowo ogrodzona",
  price: "Cena: 700 000 zł",
  buttonLabel: "Zobacz więcej",
  buttonLink:
    "https://www.morizon.pl/oferta/sprzedaz-dzialka-krakow-podgorze-350m2-mzn2042420862",
  image: Plot1,
  reverse: true,
  delay: 100,
  imageLocation: "left",
};

export const Dzialka2 = {
  heading: "Działka 250 m2 - Kraków, Podgórze",
  paragraph:
    "- działka budowlana w kształcie trapezu\n\n- częściowo ogrodzona\n\n- wymiar działki: 12x31 m",
  price: "Cena: 500 000 zł",
  buttonLabel: "Zobacz więcej",
  buttonLink:
    "https://www.morizon.pl/oferta/sprzedaz-dzialka-krakow-bronowice-250m2-mzn2042098778",
  image: Plot2,
  reverse: true,
  delay: 100,
  imageLocation: "left",
};

export const Dzialka3 = {
  heading: "Działka 500 m2 - Kraków, Podgórze",
  paragraph:
    "- działka 500 m2 z domem o powierzchni 200 m2 do remontu w cichej okolicy\n\n- częściowo ogrodzona\n\n- wymiar działki: 22x22,7 m",
  price: "Cena: 1 700 000 zł",
  buttonLabel: "Zobacz więcej",
  buttonLink:
    "https://www.morizon.pl/oferta/sprzedaz-dzialka-krakow-500m2-mzn2042098780",
  image: Plot3,
  reverse: true,
  delay: 100,
  imageLocation: "left",
};

export const Dzialka4 = {
  heading: "Działka 526 m2 - Warszawa, Włochy",
  paragraph:
    "- działka budowlana o powierzchni 526 m2\n\n- możliwość wybudowania 3-piętrowego domu na działce\n\n- sprzedaż działki wraz z pozwoleniem na budowę\n\n- dostępne media na działce: woda, kanalizacja i gaz",
  price: "Cena: 2 200 000 zł",
  buttonLabel: "Zobacz więcej",
  buttonLink:
    "https://www.morizon.pl/oferta/sprzedaz-dzialka-warszawa-wlochy-526m2-mzn2041237732",
  image: Plot4,
  reverse: true,
  delay: 100,
  imageLocation: "left",
};

export const Lokal1 = {
  heading: "Lokal 42 m2 - Kraków, Podgórze",
  paragraph:
    "- lokal z 3 pokojami o powierzchni 41,6 m2\n\n- okna wychodzą na stronę wschodnią i południową\n\n- stan do remontu\n\n- w tym roku planowana wymiana instalacji wod-kan oraz podłączenie do sieci ciepłowniczej\n\n- zlokalizowany w kameralnym budynku z 8 mieszkaniami na sporej działce",
  price: "Cena: 499 900 zł",
  buttonLabel: "Zobacz więcej",
  buttonLink:
    "https://www.morizon.pl/oferta/sprzedaz-mieszkanie-krakow-podgorze-borsucza-42m2-mzn2041034178",
  image: Venue1,
  reverse: true,
  delay: 100,
  imageLocation: "left",
};

export const Lokal2 = {
  heading: "Lokal 31 m2 - Warszawa, Włochy",
  paragraph:
    "- nowa inwestycja na Okęciu\n\n- do końca IV kwartału 2024 roku dostępne 20 mieszkań\n\n- budynek składa się z 4 kondygnacji naziemnych i 2 kondygnacji podziemnych z garażem\n\n- możliwość zakupienia miejsca postojowego za 90.000 zł\n\n- dwupokojowy lokal o powierzchni 30,94 m2 z tarasem o powierzchni 10 m2\n\n- stan deweloperski\n\n- okna mieszkania wychodzą na stronę północno-zachodnią\n\n- media dostępne w mieszkaniu: woda miejska, kanalizacja, ogrzewanie gazowe",
  price: "Cena: 309 400 zł",
  buttonLabel: "Zobacz więcej",
  buttonLink:
    "https://www.morizon.pl/oferta/sprzedaz-mieszkanie-warszawa-wlochy-30m2-mzn2041380366",
  image: Venue2,
  reverse: true,
  delay: 100,
  imageLocation: "left",
};

export const Lokal3 = {
  heading: "Lokal 78 m2 - Warszawa, Praga-Północ, Kijowska",
  paragraph:
    "- lokal użytkowy składający się z 4 pomieszczeń\n\n- zlokalizowany na kondygnacji -1\n\n- do lokalu przynależy udział w toalecie\n\n- możliwość wykonania łazienki w lokalu za opłatą",
  price: "Cena: 392 000 zł",
  buttonLabel: "Zobacz więcej",
  buttonLink:
    "https://www.morizon.pl/oferta/sprzedaz-lokal-warszawa-praga-polnoc-kijowska-78m2-mzn2041034190",
  image: Venue3,
  reverse: true,
  delay: 100,
  imageLocation: "left",
};

export const Lokal4 = {
  heading: "Lokal 15 m2 - Warszawa, Praga-Północ, Kijowska",
  paragraph:
    "- skarbiec zlokalizowany na kondygnacji -1\n\n- do lokalu przynależy udział w toalecie",
  price: "Cena: 304 000 zł",
  buttonLabel: "Zobacz więcej",
  buttonLink:
    "https://www.morizon.pl/oferta/sprzedaz-obiekt-warszawa-praga-polnoc-kijowska-15m2-mzn2041034191",
  image: Venue4,
  reverse: true,
  delay: 100,
  imageLocation: "left",
};

export const Lokal5 = {
  heading: "Lokal 18 m2 - Warszawa, Praga-Północ, Kijowska",
  paragraph:
    "- nieduży lokal użytkowy/biurowy\n\n- wyjście okien na stronę północno-zachodnią\n\n- wejście do lokalu od podwórka z klatki schodowej\n\n- lokal zlokalizowany w okolicy Dworca Wschodniego\n\n- odległość do przystanku autobusowego i tramwajowego: 150 m\n\n- odległość do dworca: 300 m\n\n- odległość do najbliższego sklepu: 400 m",
  price: "Cena: 158 000 zł",
  buttonLabel: "Zobacz więcej",
  buttonLink:
    "https://www.morizon.pl/oferta/sprzedaz-biuro-warszawa-praga-polnoc-kijowska-18m2-mzn2041034181",
  image: Venue5,
  reverse: true,
  delay: 100,
  imageLocation: "left",
};

export const Lokal6 = {
  heading: "Lokal 8 m2 - Warszawa, Praga-Północ, Kijowska",
  paragraph:
    "- mały lokal użytkowy zlokalizowany na kondygnacji -1\n\n- doskonały na sklep internetowy, mały magazyn\n\n- do lokalu przynależy udział w toalecie\n\n- możliwość wykonania łazienki w lokalu za opłatą",
  price: "Cena: 49 300 zł",
  buttonLabel: "Zobacz więcej",
  buttonLink:
    "https://www.morizon.pl/oferta/sprzedaz-lokal-warszawa-praga-polnoc-kijowska-8m2-mzn2041034184",
  image: Venue6,
  reverse: true,
  delay: 100,
  imageLocation: "left",
};

export const Lokal7 = {
  heading: "Lokal 430 m2 - Warszawa, Praga-Północ, Kijowska",
  paragraph:
    "- lokal usługowy, dwupoziomowy w jednym z najdłuższych budynków w Warszawie.\n\n- idealny na własną działalność i pod wynajem innym firmom\n\n- posiada własny garaż o powierzchni 23 m2\n\n- przed wejściem znajduje się wygodna pochylnia",
  price: "Cena: 3 300 000 zł",
  buttonLabel: "Zobacz więcej",
  buttonLink:
    "https://www.morizon.pl/oferta/sprzedaz-lokal-warszawa-praga-polnoc-kijowska-430m2-mzn2041411078",
  image: Venue7,
  reverse: true,
  delay: 100,
  imageLocation: "left",
};

export const Lokal8 = {
  heading: "Lokal 14 m2 - Warszawa, Praga-Północ, Kijowska",
  paragraph:
    "- lokal usługowy o powierzchni 14,2 m2\n\n- zlokalizowany na parterze w budynku wielorodzinnym\n\n- stani deweloperski\n\n- okno w lokalu od strony południowo-wschodniej\n\n- lokal zostanie wydzielony z dużej przestrzeni (450 m2)\n\n- planowane oddanie do użytku na koniec czerwca 2023 r.",
  price: "Cena: 128 000 zł",
  buttonLabel: "Zobacz więcej",
  buttonLink:
    "https://www.morizon.pl/oferta/sprzedaz-lokal-warszawa-praga-polnoc-kijowska-14m2-mzn2041672244",
  image: Venue8,
  reverse: true,
  delay: 100,
  imageLocation: "left",
};

export const Lokal9 = {
  heading: "Lokal 47 m2 - Warszawa, Praga-Północ, Kijowska",
  paragraph:
    "- lokal 2 pokojowy w jednym z najdłuższych budynków w Warszawie\n\n - w lokalu znajduje się łazienka i aneks kuchenny\n\n- okna wychodzą na stronę północno-zachodnią",
  price: "Cena: 418 500 zł",
  buttonLabel: "Zobacz więcej",
  buttonLink:
    "https://www.morizon.pl/oferta/sprzedaz-mieszkanie-warszawa-praga-polnoc-kijowska-46m2-mzn2041034220",
  image: Venue9,
  reverse: true,
  delay: 100,
  imageLocation: "left",
};

export const Lokal10 = {
  heading: "Lokal 24 m2 - Warszawa, Ursus",
  paragraph:
    "- lokal składający się z pokoju z aneksem kuchennym i przestronnej wykończonej łazienki\n\n- duże okno wychodzące na stronę wschodnią, a okno balkonowe na południową\n\n- lokal wymaga odświeżenia\n\n- możliwość dokupienia miejsca postojowego\n\n- odległość do przystanku autobusowego: 100 m, Dworca PKP Ursus: 350 m\n\n- odległość do Lidla: 600 m, Biedronki: 1300 m\n\n- odległość do przychodni medycznej: 500 m\n\n- odległość do przedszkola: 400 m, szkoły: 1000 m",
  price: "Cena: 249 000 zł",
  buttonLabel: "Zobacz więcej",
  buttonLink:
    "https://www.morizon.pl/oferta/sprzedaz-mieszkanie-warszawa-ursus-24m2-mzn2041678748",
  image: Venue10,
  reverse: true,
  delay: 100,
  imageLocation: "left",
};

export const Lokal11 = {
  heading: "Lokal 61 m2 - Warszawa, Ursus",
  paragraph:
    "- lokal dwupokojowy z kuchnią i łazienką\n\n- okna wychodzące na stronę południową i zachodnią\n\n- obecnie trwają prace remontowe w mieszkaniu\n\n- mieszkanie znajduje się w suterenie\n\n- możliwość dokupienia miejsca postojowego\n\n- lokal położony w okolicy parku Czechowickiego\n\n- odległość do przystanku autobusowego: 100 m, Dworca PKP Ursus: 350 m\n\n- odległość do Lidla: 600 m, Biedronki: 1300 m\n\n- odległość do przychodni medycznej: 500 m\n\n- odległość do przedszkola: 400 m, szkoły: 1000 m",
  price: "Cena: 300 000 zł",
  buttonLabel: "Zobacz więcej",
  buttonLink:
    "https://www.morizon.pl/oferta/sprzedaz-mieszkanie-warszawa-ursus-60m2-mzn2041886003",
  image: Venue11,
  reverse: true,
  delay: 100,
  imageLocation: "left",
};