import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components/macro";
//import { Link } from 'react-router-dom'
import { menuData } from "../data/MenuData"; //
import { Button } from "./Button";
import Bars from "../images/bars.svg";

import { Link } from "react-scroll";
import Icon from "../images/logo.webp";

const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? "#1974d2" : "#00000080")};
  height: ${({ scrollNav }) => (scrollNav ? "60px" : "80px")};
  height: 100px;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  z-index: 100;
  position: fixed;
  width: 100%;
  transition: 0.8s all ease-in-out;
`;

const NavLink = css`
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
`;

const Logo = styled(Link)`
  ${NavLink}
  font-weight: bold;
  font-size: 24px;

  @media screen and (max-width: 850px) {
    font-size: 20px;
    margin-right: auto;
  }
`;

const MenuBars = styled.i`
  display: none;

  @media screen and (max-width: 850px) {
    display: block;
    background-image: url(${Bars});
    background-size: contain;
    height: 50px;
    width: 50px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, 50%);
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-left: auto;

  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const NavMenuLinks = styled(Link)`
  ${NavLink}
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  &:hover {
    transform: scale(1.1);
  }
`;

export const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  return (
    <Nav scrollNav={scrollNav}>
      <img src={Icon} alt="Logo" />
      <Logo to="/" smooth={true} duration={500}>
        GRUDNIK
        <br />
        NIERUCHOMOŚCI
      </Logo>
      <MenuBars onClick={toggle} />
      <NavMenu>
        <NavMenuLinks to="o-firmie" smooth={true} duration={500}>
          O firmie
        </NavMenuLinks>
        <NavMenuLinks to="oferta" smooth={true} duration={500}>
          Oferta
        </NavMenuLinks>
        <NavMenuLinks to="galeria" smooth={true} duration={500}>
          Galeria
        </NavMenuLinks>
        <NavMenuLinks to="sprzedaz" smooth={true} duration={500}>
          Sprzedaż
        </NavMenuLinks>
        <NavMenuLinks to="kontakt" smooth={true} duration={500}>
          Kontakt
        </NavMenuLinks>
      </NavMenu>
    </Nav>
  );
};

export default Navbar;
